<template>
  <div>
  <v-app-bar dark prominent density="compact">
    <img class="mr-1 ml-1" :src="logo" width="40"/>
    <span class="mr-2 text-h6">BigToe</span>
    <v-toolbar-items>
      <v-btn text :active="this.$route.name === 'ChatBot'" :to="{ name: 'ChatBot' }">Sessions</v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-btn icon @click="signOut" aria-label="Sign Out" v-if="user">
      <v-icon>mdi-export</v-icon>
      <v-tooltip
        activator="parent"
      >Sign Out</v-tooltip>
    </v-btn>
  </v-app-bar>
</div>
</template>

<script>
import { mapState } from 'vuex';
import logo from '@/assets/logo.png';

export default {
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      navigation: [
        { name: 'My Sessions', to: '/', current: true }
      ],
      logo
    };
  },
  components: {
  },
  name: 'NavBar',
  methods: {
    signOut() {
      this.$emit('signOut');
    }
  }
}

</script>

<style scoped>
</style>