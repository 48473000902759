<template>
  <v-row justify="center">
    <v-col v-if="threads.length" lg=8 md=12 sm=12 >
      <v-btn @click="startNewThread" color="primary">
        Start New Session
      </v-btn>
      <v-data-table
        class="mt-4 h-screen"
        :headers="headers"
        :items="threads"
        item-key="id"
        density="compact"
        hide-default-footer
        fixed-header
      >
        <template v-slot:[`item.previewText`]="{ item }">
          <a @click="openThread(item.id)" style="cursor: pointer;">    
            {{ item.previewText.length > 50 ? item.previewText.substring(0, 50) + '...' : item.previewText  }}
          </a>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
      </v-data-table>
    </v-col>
    <v-col v-else>
      <div>
        <div>
          <p>No historical sessions found.</p>
        </div>
        <div>
          <v-btn @click="startNewThread" color="primary" class="mt-4">
            Start New Session
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { db } from '../firebaseInit.js'; // adjust the path according to your project structure
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { mapState } from 'vuex';

export default {
  components: {
    // import Vuetify components here
    // e.g. VBtn, VDataTable
  },
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      threads: [],
      headers: [
        { title: 'Thread', key: 'previewText', 
          value: item => item.previewText.length > 50 ? item.previewText.substring(0, 50) + '...' : item.previewText 
        },
        { title: 'Created At', key: 'createdAt' }
      ]
    };
  },
  methods: {
    startNewThread() {
      // navigate to a new thread page
      this.$router.push({ name: 'Thread', params: { id: 'new' } });
    },
    formatDate(date) {
      return date.toDate().toLocaleString(undefined, {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    openThread(id) {
      // navigate to the thread page
      this.$router.push({ name: 'Thread', params: { id } });
    }
  },
  async created() {
    // fetch historical threads from Firestore
    const q = query(collection(db, 'threads'), where('userId', '==', this.user.uid), orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);
    this.threads = querySnapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data()
      };
    });
  }
};
</script>

<style scoped>
  .format {
    margin-top: 4rem;
  }
</style>