<template>
  <v-card class="px-2 py-2" color="grey-lighten-5" rounded style="height: 65dvh; overflow-y: scroll;" flat>
    <v-row>
      <v-col>
        <div ref="chatMessages">
          <div v-for="(message) in messages" :key="message.id" :ref="message.id" class="pb-2">
            <ChatMessage :message="message" />
          </div>
          <div v-if="thinking" class="d-flex pt-4 ml-2">
            <div class="snippet" data-title="dot-typing">
              <div class="stage ml-2">
                <div class="dot-typing"></div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ChatMessage from './ChatMessage.vue';

export default {
  components: {
    ChatMessage
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    thinking: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    messages: {
      handler: function () {
        this.$nextTick(() => {
          const lastChildElement = this.$refs.chatMessages.lastElementChild;
          lastChildElement?.scrollIntoView({
            block: 'end',
            behavior: 'instant',
          });
        });
      },
      deep: true
    },
    thinking() {
      console.log("showThinking changed to " + this.thinking);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const lastChildElement = this.$refs.chatMessages.lastElementChild;
      lastChildElement?.scrollIntoView({
        block: 'end',
        behavior: 'instant',
      });
    });
  }
};
</script>

<style scoped>
.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  50% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px -10px 0 0 #9880ff;
  }
  100% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
}
</style>