<template>
  <v-row>
    <v-col cols="12" class="sign-in-cta">
      <v-card>
        <v-card-title class="headline">Sign in to BigToe</v-card-title>
        <v-card-text>
          <p>BigToe is a chatbot that helps you sound out your thoughts and ideas.</p>
          <p>Sign in to start a new session or continue an existing one.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="signInWithGoogle" color="primary">Sign in with Google</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
  
</template>

<script>
import { auth } from '../firebaseInit.js'; 
import { signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo } from 'firebase/auth';
import { mapState } from 'vuex';

export default {
  name: 'LogIn',
  computed: {
    ...mapState(['user'])
  },
  methods: {
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      try {
        const result = 
          await signInWithPopup(auth, provider);
        const { isNewUser } = getAdditionalUserInfo(result);

        this.$store.commit('setUser', result.user);
        if (isNewUser) {
          this.$router.push('/thread/new');
        }
        else {
          this.$router.push('/');
        }        
      } catch (error) {
        console.error(error);
        alert('Failed to sign in with Google');
      }
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>