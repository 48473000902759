<template>
  <v-card class="px-2 py-2" rounded density="compact" color="blue-lighten-5" variant="flat">
    <v-row>
      <v-col>
        <span class="text-subtitle-2 text-medium-emphasis font-weight-bold">
          {{ message.role === "user" ? "You" : "Assistant" }}
        </span>
        <div v-html="convertMarkdownToHtml(message.text)" class="message-text text-body-2 pt-1" :id="$style.htmlContainer"></div>
        <div v-if="fileUrls && fileUrls.length">
          <v-row v-for="(url, index) in fileUrls" :key="index" class="pt-2">
            <v-col lg="6" md="6" sm="12">
              <v-img v-if="mimeTypes[index] ? mimeTypes[index].startsWith('image') : false" :src="url" max-width="200"></v-img>
              <video v-else-if="mimeTypes[index] ? mimeTypes[index].startsWith('video') : false" :src="url" controls></video>
              <a v-else :href="url" download>Download file</a>
            </v-col>
          </v-row>
        </div>
        <div v-if="imageUrls && imageUrls.length">
          <v-row justify="start" v-for="(url, index) in imageUrls" :key="index" class="pt-2">
            <v-col lg="6" md="6" sm="12">
              <v-img :src="url" max-width="200"></v-img>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ref, getMetadata, getDownloadURL } from 'firebase/storage';
import { storage } from '@/firebaseInit';
import MarkdownIt from 'markdown-it';

export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mimeTypes: [],
      fileUrls: [],
      imageUrls: []
    }
  },
  methods: {
    convertMarkdownToHtml(markdown) {
      const md = new MarkdownIt();
      return md.render(markdown);
    }
  },
  mounted() {
    for (let i = 0; i < this.message.files.length; i++) {
      getDownloadURL(ref(storage, this.message.files[i])).then((url) => {
        this.fileUrls.push(url);
      });

      getMetadata(ref(storage, this.message.files[i])).then((metadata) => {
        this.mimeTypes.push(metadata.contentType);
      });
    }

    for (let i = 0; i < this.message.images?.length; i++) {
      getDownloadURL(ref(storage, this.message.images[i])).then((url) => {
        this.imageUrls.push(url);
      });
    }
  }
};
</script>

<style module>
.thumbnail {
  width: 100px; 
  height: 100px; 
  object-fit: cover;
} 

.message-text { 
  margin-top: -1em !important; 
  margin-bottom: -1em !important;
}

#htmlContainer p {
  margin-bottom: 2px;
}

#htmlContainer ol {
  padding-left: 2em;
}

#htmlContainer ul {
  padding-left: 2em;
}

#htmlContainer ul ul {
  padding-left: 2em;
}

#htmlContainer ol ul {
  padding-left: 2em;
}

#htmlContainer ol ol {
  padding-left: 2em;
}

#htmlContainer h1 {
  padding-top: 1em;
}

#htmlContainer h2 {
  padding-top: 1em;
}

#htmlContainer h3 {
  padding-top: 1em;
}

#htmlContainer h4 {
  padding-top: 1em;
}

#htmlContainer h5 {
  padding-top: 1em;
}

#htmlContainer h6 {
  padding-top: 1em;
}
</style>