<template>
  <v-card class="pb-2" flat rounded color="grey-lighten-5">
    <v-textarea
      v-model="input"
      placeholder="Type your message..."
      variant="underlined"
      rows="3"
      class="mx-2"
    ></v-textarea>
    <v-row v-if="images.length > 0">
      <v-col v-for="(image, index) in images" :key="index" sm="12" md="6" lg="3">
        <v-card class="ml-2">
          <v-img
              :src="imageObjectURLs[index]"
              class="align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="100px"
              cover
            ></v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="removeImage(index)" icon="mdi-close" size="small" color="medium-emphasis">
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="files.length > 0">
      <v-col v-for="(file, index) in files" :key="index" cols="auto">
        <v-chip class="inline-block ml-2" color="blue" closable @click:close="removeFile(index)">
          {{ file.name.substring(0, 10)}}
        </v-chip>
      </v-col>
    </v-row>
    <input ref="fileInput" type="file" accept="application/*, text/*" multiple style="display:none;" />
    <input ref="imageInput" type="file" accept="image/*" multiple style="display:none;" />
    <v-row justify="end">
      <v-col cols="12" align="right">
        <v-btn @click="attachImage" class="mr-2">
          <v-icon>mdi-image</v-icon>
        </v-btn>
        <v-btn @click="attach" class="mr-2">
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <v-btn :disabled="thinking || !input" @click="send" color="primary" class="mr-2">
          <v-icon>mdi-send</v-icon>
          Send
        </v-btn>
      </v-col>
    </v-row>
  </v-card>     
</template>

<script>
export default {
  data() {
    return {
      input: '',
      files: [],
      images: [],
      imageObjectURLs: []
    };
  },
  props: {
    thinking: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    send() {
      this.$emit('send', { text: this.input, files: this.files, images: this.images });
      this.input = '';
      this.files = [];
      this.images = [];
      this.imageObjectURLs = [];
    },
    attach() {
      console.log("attach");
      this.$refs.fileInput.click();
    },
    attachImage() {
      console.log("attachImage");
      this.$refs.imageInput.click();
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.imageObjectURLs.splice(index, 1);
    }
  },
  mounted() {
    this.$refs.fileInput.addEventListener('change', (event) => {
      const files = Array.from(event.target.files);
      const totalSize = files.reduce((total, file) => total + file.size, 0) / (1024 * 1024); // size in MB
      if (files.length > 5 || totalSize > 100) {
        alert('You can only attach up to 5 files or 100MB total.');
      } else {
        this.files = files;
      }
    });
    this.$refs.imageInput.addEventListener('change', (event) => {
      const images = Array.from(event.target.files);
      const totalSize = images.reduce((total, file) => total + file.size, 0) / (1024 * 1024); // size in MB
      if (images.length > 5 || totalSize > 100) {
        alert('You can only attach up to 5 images or 100MB total.');
      } else {
        this.images = images;
        this.imageObjectURLs = images.map(image => URL.createObjectURL(image));
      }
    });
  }
};
</script>

<style scoped>
.user-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}

.image-thumb, .image-thumb img {
  position:relative;
  width:100px;
}
.image-fav {
  display:block;
  position:absolute;
  top:0;
  right:0;
  width:20px;
  height:20px;
  cursor: pointer;
}
</style>